body {
  margin: 0;
  display: flex;
  /* background-color: #141414;
  color: white; */
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > * {
  flex: auto;
}

/* .domain,
.gridline-y,
.gridline-x,
.annotation-line > line {
  stroke: currentColor;
  stroke-opacity: 0.1;
} */



.d3fc {
  min-height: 100px;
}
d3fc-canvas {
  min-height: 100px;
}
d3fc-svg {
  min-height: 20px;
}

.d3fc.tradesChart {
  /* min-height: 35vh; */
}
.d3fc.tradesChart d3fc-canvas {
  /* min-height: 35vh; */
}

.d3fc .tick text{
  fill: white;
}

.domain, .gridline-y, .gridline-x, .annotation-line>line {
  stroke: rgba(255,255,255,0.05);
  stroke-opacity: 0.01;
}
